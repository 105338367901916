<template>
	<div id="cashflowTicket" align="center" v-show="ticketVisible">
		<table border="0" width="450">
			<tr>
				<td align="center" colspan="3">
					<div class="txt1" style="padding-top: 5mm">Patito Cervecera</div>
					<div class="txt3">Corte de caja</div>
					<div class="txt3">=========================================</div>
				</td>
			</tr>
			<tr>
				<td width="50%" align="center" class="txt2">
					<b>Corte</b>
					<br />
					#{{ ticketData.id }}
				</td>
				<td width="50%" align="center" class="txt2">
					<b>Fecha y hora</b>
					<br />
					{{ ticketData.created_at }} hrs.
				</td>
			</tr>
			<tr>
				<td colspan="3" align="center" class="txt2">
					<b>=== Cajero(a) ===</b>
					<br />
					{{ ticketData.cashier }}
				</td>
			</tr>
			<tr>
				<td align="center" colspan="3">
					<div class="txt3">=========================================</div>
				</td>
			</tr>
		</table>
		<table border="0" width="450">
			<tr>
				<td width="33%" align="center" class="txt2">
					<b>Total</b>
				</td>
				<td width="33%" align="center" class="txt2">
					<b>Total por anticipos</b>
				</td>
				<td width="33%" align="center" class="txt2">
					<b>Descuentos</b>
				</td>
			</tr>
			<tr>
				<td width="33%" align="center" class="txt2">
					<b>{{ numeral(ticketData.total).format('$0,0.00') }}</b>
				</td>
				<td width="33%" align="center" class="txt2">
					<b>{{ numeral(ticketData.totalAdvances).format('$0,0.00') }}</b>
				</td>
				<td width="33%" align="center" class="txt2">
					<b>{{ numeral(ticketData.discounts).format('$0,0.00') }}</b>
				</td>
			</tr>
		</table>
		<table border="0" width="450">
			<tr>
				<td align="center" class="txt2 pt10">
					<b>=== Ingresos ===</b>
				</td>
			</tr>
			<tr>
				<td align="right" class="txt3 p07"><b>Efectivo:</b> {{ numeral(ticketData.payment_cash).format('$0,0.00') }}</td>
			</tr>
			<tr>
				<td align="right" class="txt3 p07"><b>Tarjetas:</b> {{ numeral(ticketData.payment_bank_terminal).format('$0,0.00') }}</td>
			</tr>
			<tr>
				<td align="right" class="txt3 p07"><b>Transferencia:</b> {{ numeral(ticketData.payment_transfer).format('$0,0.00') }}</td>
			</tr>
			<tr>
				<td align="right" class="txt3 p07"><b>PayPal:</b> {{ numeral(ticketData.payment_paypal).format('$0,0.00') }}</td>
			</tr>
			<tr>
				<td align="right" class="txt3 p07"><b>MercadoPago:</b> {{ numeral(ticketData.payment_mercadopago).format('$0,0.00') }}</td>
			</tr>
		</table>
		<table border="0" width="450">
			<tr>
				<td align="center" class="txt2 pt10">
					<b>=== Anticipos ===</b>
				</td>
			</tr>
			<tr>
				<td align="right" class="txt3 p07"><b>Efectivo:</b> {{ numeral(ticketData.advances.payment_cash).format('$0,0.00') }}</td>
			</tr>
			<tr>
				<td align="right" class="txt3 p07"><b>Tarjetas:</b> {{ numeral(ticketData.advances.payment_bank_terminal).format('$0,0.00') }}</td>
			</tr>
			<tr>
				<td align="right" class="txt3 p07"><b>Transferencia:</b> {{ numeral(ticketData.advances.payment_bank_transfer).format('$0,0.00') }}</td>
			</tr>
			<tr>
				<td align="right" class="txt3 p07"><b>PayPal:</b> {{ numeral(ticketData.advances.payment_paypal).format('$0,0.00') }}</td>
			</tr>
			<tr>
				<td align="right" class="txt3 p07"><b>MercadoPago:</b> {{ numeral(ticketData.advances.payment_mercadopago).format('$0,0.00') }}</td>
			</tr>
		</table>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import numeral from 'numeral'
import VueHtmlToPaper from 'vue-html-to-paper'
import utilities from '@/services/utilities'

const options = {
	name: '_blank',
	specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
	styles: ['https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css', 'resources/css/printTickets.css'],
	timeout: 1000, // default timeout before the print window appears
	autoClose: true, // if false, the window will not close after printing
	windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

export default {
	name: 'cashflowTicketComponent',
	computed: {
		...mapGetters('cashFlow', ['ticketData']),
	},
	data() {
		return {
			ticketVisible: false,
		}
	},
	methods: {
		numeral,
	},
	watch: {
		ticketData(newData) {
			if (utilities.objectValidate(newData, 'id', false)) {
				this.ticketVisible = true

				setTimeout(() => {
					this.$htmlToPaper('cashflowTicket')
				}, 500)

				setTimeout(() => {
					this.ticketVisible = false
				}, 3000)
			}
		},
	},
}
</script>
