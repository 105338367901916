<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<a-form :form="form" @submit="handleSubmit">
				<div class="row">
					<div class="col-md-12">
						<a-form-item label="Monto de descuento">
							<a-input autocomplete="off" type="number" prefix="$" v-decorator="[
								'amount',
								{
									rules: [
										{
											required: true,
											message: 'Ingresa monto del ticket',
										},
									],
								},
							]" />
						</a-form-item>
					</div>
					<!-- <div class="col-md-6">
						<a-form-item label="Área de negocio">
							<a-select
								style="width: 100%"
								v-decorator="[
									'area',
									{
										rules: [
											{
												required: true,
												message: 'Selecciona el área a aplicar el descuento',
											},
										],
									},
								]"
							>
								<a-select-option value="boutique">Piso de Venta</a-select-option>
								<a-select-option value="servicio">Mano de Obra</a-select-option>
								<a-select-option value="refacciones">Refacciones</a-select-option>
							</a-select>
						</a-form-item>
					</div> -->
				</div>
				<a-button size="large" type="primary" htmlType="submit" class="text-center" id="discountComponent_submit" v-show="false">
					<strong>Guardar</strong>
				</a-button>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'discountComponent',
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('pos', ['spinnerLoaderLabel', 'spinnerLoader']),
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
		}
	},
	methods: {
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					this.$emit('ok', values)
				}
			})
		},
	},
	watch: {
		visible(isVisible) {
			if (!isVisible) {
				this.form.resetFields()
			}
		},
	},
}
</script>
